import React from "react";
import IndexView from "../views/IndexView";

class IndexController extends React.Component{
    render(){
        return(
            <IndexView>
             
            </IndexView>
        )
    }
}

export default IndexController