var PubSub = require('pubsub-js');

const constants = {
    IS_LOADING: "IS_LOADING",


}

var pub_function = function (event) {
    return function (data) {
        return PubSub.publish(event, data);
    }
}

var sub_function = function (event) {
    return function (callback) {
        return PubSub.subscribe(event, callback);
    }
}


var publish = {
    is_loading: pub_function(constants.IS_LOADING),
}

var subscribe = {
    is_loading: sub_function(constants.IS_LOADING),
}

module.exports = {
    publish: publish,
    subscribe: subscribe,
}
