import React, { Component,} from "react";
import {Routes, Route} from 'react-router-dom'

import IndexController from "./controllers/IndexController";

import './styles'
import './scripts'

class App extends Component{
    render(){
        return(
            <Routes>
                <Route rel="canonical" exact path='/' element={<IndexController/>}/>
            </Routes>
        );
    }

    componentDidMount(){
      window.scrollTo(window.scrollX, window.scrollY + 1);    
    }
}

export default App;
